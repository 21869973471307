import * as React from 'react';
import * as moment from 'moment';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/global/seo';
import { BlogUtil } from '../util/blog-util';
import OtherLayout from '../components/other/other-layout';

import './blog.scss';


const BlogListPage = ({ data }) => {
  const posts = BlogUtil.getPosts(data);
  return (
    <OtherLayout className="blog-list-page">
      <SEO title="Blog"/>
      <h1>Blog Obi2b</h1>
        {
          posts.map((post, index) => {
            let gatsbyImage = <></>;
            if (post.frontmatter.cover && post.frontmatter.cover.childImageSharp) {
              gatsbyImage = <Img fixed={post.frontmatter.cover.childImageSharp.fixed}
                                 alt={post.frontmatter.title}
                                 title={post.frontmatter.title}/>;
            }
            return (
              <Link to={post.frontmatter.path} key={post.fileAbsolutePath}>
                <article className="blog-list-item">
                  <div className="thumbnail">{gatsbyImage}</div>
                  <div className="text">
                    <header>
                      <h2>{post.frontmatter.title}</h2>
                      <p>
                      {moment(post.frontmatter.date).format("DD/MM/YYYY")} -
                      par {BlogUtil.getAuthors(post).map(author => author.id).join(", ")}
                      </p>
                    </header>
                    <p className="desc">{post.frontmatter.description}</p>
                  </div>
                </article>
              </Link>
            );
          })
        }
    </OtherLayout>
  )
};

export default BlogListPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 1000
      filter: {
        fileAbsolutePath: {regex: "/content\\/blog\\//"}
        frontmatter: { draft: { eq: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            path
            title
            description
            cover {
              absolutePath
              childImageSharp {
                fixed(width: 250, height: 180) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            author {
              id
              bio
              twitter
            }
            authors {
              id
              bio
              twitter
            }
            category
            tags
          }
        }
      }
    }
  }
`;
